<template>
    <section>
        
        <b-carousel
            v-model="carousel"
            :animated="animated"
            :has-drag="drag"
            :autoplay="autoPlay"
            :pause-hover="pauseHover"
            :pause-info="pauseInfo"
            :pause-info-type="pauseType"
            :interval="interval"
            :repeat="repeat"
            >
            <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
                <section :class="`hero is-medium ${carousel.background} is-bold`">
                    <div class="paddingImgTexto has-text-centered">
   
                        <h1 class="title has-text-white fontWeigth300 letterSpacing1 font2">
                            {{carousel.title}}
                            <font-awesome-icon :icon="['fa', 'eye']" class="iconoGrande" />
                        </h1>
                        <p class="title is-4 has-text-white fontWeigth300 letterSpacing1 font2">
                            {{carousel.subtitulo}}
                        </p>

                    </div>
                </section>
            </b-carousel-item>
        </b-carousel>
        
    </section>
</template>

<script>

export default {
    
    name: 'CmpImgText1',

    data() {
        return {
            carousel: 0,
            animated: 'slide',
            drag: false,
            autoPlay: true,
            pauseHover: true,
            pauseInfo: false,
            repeat: true,
            pauseType: 'is-primary',
            interval: 3000,
            carousels: [

                { title: '', subtitulo: 'Una nueva forma de construir sueños',
                background: 'imgTexto1' },

            ]
        }
    },

    methods: {
        info(value) {
            this.carousel = value
            this.$buefy.toast.open({
                message: `This Slide ${value} !`,
                type: 'is-info'
            })
        }
    }
}
</script>

<style>

    .paddingImgTexto {
        padding-top:8rem;
        padding-bottom:8rem;
    }

    .iconoGrande {
        font-size:80px
    }

    .carousel .carousel-indicator .indicator-item .indicator-style.is-dots {
    border-radius: 10px;
    height: 10px;
    width: 10px;
    display:none!important;
    }

    .carousel-arrow .icon.has-icons-right {
    right: 1.5rem;
    display: none !important;
    }

    .carousel-arrow .icon.has-icons-left {
    right: 1.5rem;
    display: none !important;
    }

    .carousel {
    min-height: 300px !important;
    position: relative;
    }

    .carousel .carousel-items {
    min-height: 300px !important;
    }

    .hero {
    min-height: 300px !important;
    }

    .imgTexto1 {
     background-image: url("../../../assets/img/images/2.jpg");
     background-size:cover;
    }


    /* Texto */
    /* Texto */

    .fontWeigth300 {
        font-weight:300!important;
    }

    .fontWeigth400 {
        font-weight:400!important;
    }

    .fontWeigth600 {
        font-weight:600!important;
    }

    .fontWeigth700 {
        font-weight:700!important;
    }



</style>