<template>
    <div>
        <CmpUserProfile />
    </div>
</template>

<script>
// Llamo al mapState para poder recibir los datos del Usuario y comprobar si existe una Sesion
import { mapState } from "vuex";
import CmpUserProfile from '@/components/userSession/UserProfile.vue'

export default {
    name: 'VUserProfile',
    components: {
        CmpUserProfile
    },
}
</script>