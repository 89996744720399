<template> 
    <div v-if="userExist" class="marginTopSeccion">
        <div class="closeSearchBarOnClick " @click="modalCity = false; modalProvince = false"></div>
      
        <CmpSidebar />  <!-- Sidebar 
        <CmpSidebarMobile />-->

        <div class="columns">
            <div class="column is-full p-0">
                <div class="barraSeccion bgColorPrimary colorWhite title is-6 fontWeigth400 letterSpacing05">
                Perfil de <span v-if="!loader">{{userNode.username}}</span>       
                <pulse-loader  :loading="loader" :color="colorLoader" v-if="loader" class="is-inline-block"></pulse-loader> 
                </div>
            </div>
        </div> 
    
        <div class="columns p-0 bgColorNotification">
                <div class="column is-full">
                    <div class="containerResponsivo"> 

                        <p class="fontWeigth600">Datos de usuario </p>
                  
                <!-- Datos -->
                    <div class="column is-one-quarter p-0" v-if="!hiddeProfileData && userNode">

                        <div class="mt-2 mb-2">

                        <p class="is-inline-block"  v-if="!loader && userNode.username">{{userNode.username}}</p>
                        <div class="is-block" v-if="loader">
                            <pulse-loader  :loading="loader" :color="colorLoader"></pulse-loader> 
                        </div>
                        <div class="separador"></div>
                        <p class="is-inline-block"   v-if="userNode.person.email">{{userNode.person.email}}</p>
                        <div class="is-block" v-if="loader">
                            <pulse-loader  :loading="loader" :color="colorLoader"></pulse-loader> 
                        </div>
                        <div class="separador"></div>
                        <p class="is-inline-block"   v-if="!loader && userNode.person.phone">{{userNode.person.phone}}</p> 
                        <div class="is-block" v-if="loader">
                            <pulse-loader  :loading="loader" :color="colorLoader"></pulse-loader> 
                        </div>     
                        <div class="separador"></div>
                        <p class="is-inline-block"   v-if="!loader">{{userNode.person.dni}}</p> 
                        <div class="is-block" v-if="loader">
                            <pulse-loader  :loading="loader" :color="colorLoader"></pulse-loader> 
                        </div>                 
                        <div class="separador"></div>
                        <p class="is-inline-block"   v-if="!loader">{{userNode.person.city.name}}</p>   
                        <div class="is-block" v-if="loader">
                            <pulse-loader  :loading="loader" :color="colorLoader"></pulse-loader> 
                        </div>
                        <div class="separador"></div>
                        <p class="is-inline-block"   v-if="!loader">{{userNode.person.address}}</p> 
                        <div class="is-block" v-if="loader">
                            <pulse-loader  :loading="loader" :color="colorLoader"></pulse-loader> 
                        </div>         
                        <br>
                        <b-button
                        @click="actnHiddeProfileData({hiddeProfileData:hiddeProfileData});cityId=userNode.person.city.id"
                        class="bgColorPrimary has-text-centered colorWhite mt-1  outlineButtonsPrimary zIndexLabel"
                        v-show="!hiddeProfileData">
                        Editar datos
                        </b-button>
                        </div>

                    </div>


                <!-- Modificar datos v-if="hiddeProfileData" -->
                    <div class="column is-full p-0 mt-2" v-if="hiddeProfileData" >
       <div class="maxWidthForm">
                        
                     <div class="mt-2 zIndexLabel" v-if="!createdCorralon">

                            <p class="mb-2 fontWeigth600 subtitle is-5 zIndexLabel" style="cursor:pointer;position:relative"
                            @click="actnHiddeProfileData({hiddeProfileData:true})">
                                <font-awesome-icon  :icon="['fa', 'arrow-left']" class="colorPrimary zIndexLabel"/>
                                Volver 
                            </p>
                    </div>
               <form @submit.prevent="submitUpdateUser">
                       
                            <!-- Nombre -->
                            
                            <b-field label="Nombre" class="zIndexLabel" :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="Nombre y apellido"
                                v-model="$v.name.$model"
                                :class="{'SuccessInput' : !$v.name.$invalid, 'NormalInput' : $v.name.$invalid}"
                                 >
                                </b-input>

                            </b-field>                            
                            <p v-if="$v.name.$error === true" class="subtitle is-7 mb-1 colorSoftRed">Campo requerido, debe tener más de 4 caracteres</p>

                            <!-- Email -->
                            <!--
                            <b-field  label="Email"  :label-position="labelPosition">
                                <b-input type="email"
                                    placeholder="Email"
                                    v-model="$v.email.$model"
                                    :class="{'SuccessInput' : !$v.email.$invalid, 'NormalInput' : $v.email.$invalid}"
                                    maxlength="100"
                                    :has-counter="false"
                                  >
                                </b-input>
                                <p class="is-inline-block">{{datosUsuario.email}}</p>
                            </b-field>
                            <p v-if="$v.email.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido, ingrese un email</p>

                            <div class="mb-2 is-block"></div>
                            -->

                            <div class="mb-2 is-block"></div>

                            <!-- Telefono -->
                            <b-field label="Telefono" class="zIndexLabel" :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="Telefono"
                                v-model="$v.telefono.$model"
                                :class="{'SuccessInput' : !$v.telefono.$invalid, 'NormalInput' : $v.telefono.$invalid}"
                                 >
                                </b-input>
                            </b-field>
                            <p v-if="$v.telefono.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido</p>                            

                            <div class="mb-2 is-block"></div>
                            
                            <!-- DNI -->
                            <b-field label="Dni" class="width300  zIndexLabel" :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="DNI"
                                v-model="$v.dni.$model"
                                :class="{'SuccessInput' : !$v.dni.$invalid, 'NormalInput' : $v.dni.$invalid}"
                                 >
                                </b-input>
                            </b-field>
                            <p v-if="$v.dni.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido</p>    

                           <!-- Direccion -->
                            <b-field label="Direccion"  class="width300 mt-2 zIndexLabel"  :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="Direccion"
                                v-model="$v.address.$model" 
                                :class="{'SuccessInput' : !$v.address.$invalid, 'NormalInput' : $v.address.$invalid}"
                                 >
                                </b-input>
                            </b-field>        
                            <p v-if="$v.address.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido</p>                         

                            <div class="mb-2 is-block"></div>

                            <div class="mb-2 is-block"></div>
                                                       
                                                
                                                        <div class="mb-2 is-block"></div>

                                                        <div class="mb-0 is-inline-block w100">
                                                        <b-field class="mb-0 zIndexLabel" label="Seleccionar provincia de la lista"  :label-position="labelPosition">
                                                            <b-input
                                                                type="text"
                                                                placeholder="Buscar provincia..."
                                                                custom-class="zIndexLabel"
                                                                v-model="province"
                                                                autocomplete="off"
                                                                @focus="modalProvince = true,modalCity = false"
                                                                >
                                                            </b-input>
                                                        </b-field>
                                                        <div v-if="filteredProvinces && modalProvince">
                                                            <ul class="searchBarProvincesCorralon"  v-if="province.length > 0">
                                                                <li class="listCitys lineHeigth1"
                                                                    v-for="(filteredProvince, index) in filteredProvinces" :key="index"
                                                                    @click="setProvince(filteredProvince)">{{ filteredProvince.name }}</li>
                                                            </ul>
                                                        </div>
                                                        </div>

                                                        <div class="mb-1 is-block"></div>

                                                        <div class="mb-2 is-inline-block w100">
                                                        <b-field class="mb-0 mt-1 zIndexLabel" label="Seleccionar ciudad de la lista"  :label-position="labelPosition">
                                                            <b-input
                                                                type="text"
                                                                placeholder="Buscar ciudad..."
                                                                custom-class="lowerCaseInput"
                                                                v-model="city"
                                                                autocomplete="off"
                                                                :disabled="setProvinceState === false"
                                                                @focus="modalCity = true,modalProvince = false"
                                                                >
                                                            </b-input>
                                                        </b-field>    
                                                        <div v-if="filteredCitys && modalCity">
                                                            <ul class="searchBarProvincesCorralon">
                                                                <li class="listCitys firstLetter"
                                                                    v-for="(filteredCity, index) in filteredCitys" :key="index"
                                                                    @click="setCity(filteredCity)">
                                                                    <span  class="firstLetter">
                                                                        {{ filteredCity.name }} 
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        </div>
                                                           
                            
                            <!-- Boton -->
                            <div class="column p-0">
                                      <b-button tag="input"
                                      native-type="submit"
                                      class="bgColorPrimary colorWhite outlineButtonsPrimary zIndexLabel"
                                      value="Editar usuario"
                                      v-on:click="show = !show"
                                      >Editar usuario</b-button>
                                      <b-button
                                      style="display:none"
                                      class="buttonVolver title is-7 mt-1 zIndexLabel"
                                      @click="actnHiddeProfileData({hiddeProfileData:true})"
                                      >Volver</b-button>
                            </div>
                                
                    </form>
        </div>
                    </div>

                    <!--
                    <div class="column is-two-thirds p-0 backgroundImg heightBg is-hidden-mobile w100">
                    </div>
                    -->

            </div>   
           </div>
        </div>
       
    </div>
</template>

<script>

    import CmpSidebar from '@/components/userSession/sidebar/sidebar.vue'
    import CmpSidebarMobile from '@/components/userSession/sidebar/sidebarMobile.vue'
    import CmpImgText1 from '@/components/home/cmpChildren/ImgText1.vue'
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
    import { mapState, mapActions, mapGetters } from "vuex";

    export default {
        
        name: 'CmpUserProfile',

        data() {
            return {
                show: true,
                email:'',
                name:'',
                labelPosition: 'on-border',
                password: '',
                repeatPassword: '',
                telefono: '',
                dni: '',
                address:'',


                // Searchbar
                province: '',
                provinces: [],
                provincesId:'',
                filteredProvinces: {name:'name',id:'id'},
                modalProvince: false,
                setProvinceState: false,

                city: '',
                cityId: '',
                citys: null,
                filteredCitys: [{name}],
                modalCity: false,
                setCityState: false,
            }
        },

        components: {
            CmpSidebar,
            CmpSidebarMobile,
            CmpImgText1,
            PulseLoader
        },

        created() {
            this.getProvince();
            this.getCity();
            this.fromRoute(this.$route.name);
                        this.searchDataUser({
                email: this.user.email,
                uid: this.user.uid
            });
        },

 
        mounted() {
            this.searchDataUser({
                email: this.user.email,
                uid: this.user.uid
            });
            this.goTop()

        },

        //Validacion con vuevalidate
        validations: {
            email: {
                required,
                email,
                minLength: minLength(4)
            },
            name: {
                required,
                minLength: minLength(4)
            },
            telefono: {
                required,
                minLength: minLength(4)
            },
            address: {
                required,
                minLength: minLength(4)
            },
            dni: {
                required,
                minLength: minLength(4)
            },
        },
        
        methods: {

            ...mapActions('sessions',['searchDataUser', 'updateUser', 'actnHiddeProfileData']),
            ...mapActions('generalFunctions',['fromRoute']),

                goTop() {
                        window.scrollTo(0, 0);
                    },

            submitUpdateUser() {
                  //  console.log('Id person' + this.userNode.person.id)
                  //  console.log('City id' + this.cityId)
                    this.updateUser({
                        email: this.user.email,
                        uid: this.user.uid,
                        name: this.name,
                        phone: this.telefono,
                        dni: this.dni,
                        address: this.address,
                        city: this.cityId,
                        personId: this.userNode.person.id
                    });
                    this.name = '',
                    this.telefono = '',
                    this.dni = '',
                    this.address = '',
                    this.cityId = '',
                    this.province = '',
                    this.city = ''

            },
            

            getProvince(){
            this.axios.get('/provinces/')
            .then(res => {       
                const arrayProvincias = res.data;  
                // Construyo el Array provincia
                this.provinces =  arrayProvincias.map(({ id, name }) => ({ id, name }));
                this.filterProvinces();
            })
            .catch(error => {
                this.provinces = [{name: 'No se encontraron provincias'}]
            })
            },

            filterProvinces(){
        

                if (this.province.length == 0) {
                this.filteredProvinces = this.provinces;
                }

                const provincesNameId = this.provinces;

                // Tengo que averiguar como aplicar un filtro sobre un objeto array
                this.filteredProvincesLowerCase = provincesNameId.filter(province => {
                        return province.name.toLowerCase().includes(this.province.toLowerCase());
                })

                //console.log('Fuera del filtro')
                //console.log(this.filteredProvincesLowerCase)
                this.filteredProvinces = this.filteredProvincesLowerCase.slice(0, 6);

            },

            setProvince(province) {
                //console.log(province)
                this.province = province.name;
                this.modalProvince = false;
                this.provincesId = province.id;

                this.setProvinceState = true;
                
                this.getCity();
            },
            
            getCity(){

                if(this.provincesId != '') {
                this.axios.get('/provinces/'+this.provincesId+'/cities')
                .then(res => {    
                    const arrayCitys = res.data;  
                    this.citys =  arrayCitys.map(({ name, id }) => ({ name, id }));
                    //console.log(this.citys)
                    this.filterCitys();
                })
                .catch(error => {
                    this.citys = [{name: 'No se encontraron ciudades'}]
                })
                }
            
            },

            filterCitys() {

                if (this.city.length == 0) {
                    this.filteredCitys = this.citys;
                }

               this.filteredCitys  = this.citys.filter(city => {
                    return city.name.toLowerCase().includes(this.city.toLowerCase());
                })
              //  console.log('Filtrado')
              //  console.log(this.filteredCitysLowerCase)

                //this.filteredCitys = this.filteredCitysLowerCase.slice(0, 6);
            },

            setCity(city) {
                this.city = city.name;
                this.cityId = city.id;
              // console.log('Home city id ' + this.cityId)
                this.modalCity = false;
                this.setCityState = true;
            },

        },

        computed:{
            ...mapState('sessions',['user', 'userNode', 'hiddeProfileData','datosUser']),
            ...mapState('generalFunctions',['loader', 'colorLoader']),
            ...mapGetters('sessions',['userExist'])
        },   
                
        watch: {
                city() {
                    this.filterCitys();
                },
                province() {
                    this.filterProvinces();
                }
        },

    }

</script>

<style scoped>
    .w100 {
        width:100%!important;
    }

    .maxWidthForm {
        max-width:300px;
    }

   .firstLetter {
        text-transform: lowercase;
    }

    .firstLetter:first-letter {
        text-transform: uppercase!important;
    }

    .lowerCaseInput {
        text-transform: lowercase!important;
    }

    .field:not(:last-child) {
        margin-bottom: 0rem;
    }

    .lineHeigth1 {
        line-height: 30px;
    }

    .searchBarProvincesCorralon {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:6;
        padding-top:0px;
        padding-bottom:10px;
        width:300px;
        cursor:pointer;
        padding:10px;
        text-align:center;
        max-height:200px;
        overflow:auto;
        max-width:300px
    }

    .buttonVolver {
        background-color:#f5f5f5!important;
        outline:none!important;
        border:0px!important;
        box-shadow: 0px 0px 0px 0px #333 !important;
    }

    .bgColorButtonVolver:hover {
        background-color:#f5f5f5!important;
    }

    .zIndexLabel {
        z-index:2!important;
    }

   .closeSearchBarOnClick {
        background-color:transparent;
        position:absolute;
        top: 0;
        z-index:1;
        right: 0;
        bottom: 0;
        left: 0;
    }
  
    .marginTopSeccion {
        margin-top:12px;
    }
    .outlineButtonsPrimary {
        outline: none!important;
        border:0px!important;
        box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0) !important;
    }

    .outlineButtonsPrimary:hover {
        outline: none!important;
        border:0px!important;
        box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0) !important;
        background-color:#9427dd!important
    }

    .outlineButtonsPrimary:focus {
        outline: none!important;
        border:0px!important;
        box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0) !important;

    }
    .columns {
    margin-left: 0rem; 
    margin-right: 0rem; 
    margin-top: -0.75rem;
    }       


    .backgroundImg {
        background-image: url("../../assets/img/slider/1.jpg");
        background-size:cover;
    }

 
    .sinOverflow {
        overflow-x: none !important;
    }

    .bgColorNotification {
        background-color:whitesmoke;
        border-radius:5px
    }

    .barraSeccion {
        margin-left:240px;
        margin-top:25px;
        padding-left:50px;
        padding-top:15px;
        padding-bottom:15px;
    }
 
    .containerResponsivo {
       margin-left:280px;
       margin-top:40px;
    }

    @media screen and (max-width: 1023px) {

        .sinOverflow {
            overflow-x: hidden !important;
        }

        .containerResponsivo {
            margin-left:0px;
            margin-top:0px;
        }
            
        .barraSeccion {
            margin-left:0px;
            margin-top:0px;
            padding-left:50px;
            padding-top:15px;
            padding-bottom:15px;
        }
 
    }

    .separador {
        background-color:rgb(200, 200, 200);
        height:1px;
        width:200px; 
        margin-top:9px;   
        margin-bottom:9px;   
    }

    .fotoPerfilUsuario {
        width:150px;
        border-radius:50%;
        padding:8px;
    }
    
    .card-1 {
    box-shadow: 0 0px 2px 0px rgba(0,0,0,0.16), 0 0px 10px 2px rgba(0,0,0,0.07);
    }


/* Width */
/* Width */

.w100 {
    width:100%!important;
}

.maxW300 {
    max-width:300px!important;
}

.maxW200 {
    max-width:200px!important;
}

.maxW100 {
    max-width:100px!important;
}

.maxW50 {
    max-width:50px!important;
}



    /* TextStyle */
    /* TextStyle */

    .lineHeigth0 {
        line-height: 0px;
    }

    .lineHeigth1 {
        line-height: 30px;
    }

     .letterSpacing05 {
        letter-spacing: 0.5px;
    }

     .letterSpacing1 {
        letter-spacing: 1px;
    }

     .letterSpacing2 {
        letter-spacing: 2px;
    }

    .letterSpacing3 {
        letter-spacing: 3px;
    }
    


    /* Colors text */
    /* Colors text */

    .colorPrimary {
        color:#8752aa!important;
    }

    .colorSecondary {
        color:#ff7850!important;
    }

    .colorTertiary {
        color:#ffc627!important;
    }

    .colorGrey {
        color:rgb(112, 112, 112)!important;
    }

    .colorBlack {
        color:rgb(24, 24, 24)!important;
    }

    .colorSoftRed {
        color:rgb(219, 53, 53)!important;
    }


    .colorRed {
        color:red!important;
    }

    .colorYellow {
        color:rgb(233, 116, 21);
    }

    .colorSuccess {
        color:seagreen!important;
    }

    .colorWhite {
        color:rgb(255, 255, 255)!important;
    }


    /* Colors bg */
    /* Colors bg */

    .bgColorPrimary {
        background-color:#8752aa!important;
    }

    .bgColorSecondary {
        background-color:#ff7850!important;
    }

    .bgColorTertiary {
        background-color:#ffc627!important;
    }

    .bgColorSoftRed {
        background-color:#f64444!important;
    }

    .bgColorSoftBlue {
        background-color:#3d40fd!important;
    }


    /* Texto */
    /* Texto */

    .fontWeigth300 {
        font-weight:300!important;
    }

    .fontWeigth400 {
        font-weight:400!important;
    }

    .fontWeigth600 {
        font-weight:600!important;
    }

    .fontWeigth700 {
        font-weight:700!important;
    }


    /* Bordes */
    /* Bordes */

    .borderPrimary {
        border-color:#8752aa!important;
    }

    .borderSecondary {
        border-color:#ff7850!important;
    }

    .borderTertiary {
        border-color:#ffc627!important;
    }

    
    /* Padding */
    /* Padding */
    .p-0 {
        padding:0px!important;        
    }

    .p-1 {
        padding:20px!important;
    }

    .p-2 {
        padding:20px!important;
    }

    .p-3 {
        padding:30px!important;
    }

    .p-4 {
        padding:40px!important;
    }
    /* Margins */
    /* Margins */
   .m-0 {
        margin-top:0px!important;
    }

    .mt-1 {
        margin-top:10px!important;
    }

    .mb-1 {
        margin-bottom:10px!important;
    }


    .mt-2 {
        margin-top:20px!important;
    }

    .mb-2 {
        margin-bottom:20px!important;
    }


    .mt-8 {
        margin-top:80px!important;
    }

    .mb-8 {
        margin-bottom:80px!important;
    }


  </style>